<template>
  <div class="members main-cnt">
    <div class="left-wp">
      <div class="input-wp">
        <el-input
          v-model.trim="filterText"
          clearable
          @keypress="getTreeData()"
          placeholder="输入组织名称搜索"
          :suffix-icon="Search"
        />
      </div>
      <div
        class="tree-wp"
        v-loading="treeLoading"
        element-loading-text="数据加载中"
      >
        <el-scrollbar style="height: 100%">
          <el-tree
            ref="tree"
            :data="treeData"
            :props="{
              children: 'group_list',
              label: 's_name',
            }"
            show-checkbox
            check-strictly
            accordion
            node-key="tree_monitor_id"
            :default-expanded-keys="defaultExpanded"
            :default-checked-keys="defaultChecked"
            :expand-on-click-node="false"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse"
          >
            <template #default="{ node, data }">
              <span class="custom-tree-node">
                <span @click="nodeClick(data)">
                  {{ node.label }}
                  {{
                    "(" +
                    data.device_online_count +
                    "/" +
                    data.device_all_count +
                    ")"
                  }}

                  <span v-if="!data.s_id" class="text-gray">{{
                    data.mg_status == 2 ? "(禁用)" : ""
                  }}</span>
                </span>

                <span v-if="data.s_id">
                  <a style="margin-left: 8px">
                    <!-- <el-icon color="#0072F6"><CaretTop  /></el-icon> -->
                    <!-- <el-icon color="#0072F6"><CaretBottom /></el-icon> -->
                  </a>
                </span>
                <span v-else>
                  <el-icon
                    color="#0072F6"
                    v-if="
                      authData.indexOf('n_KLF6TYELt0lWJcY6WBgqf1sZKho') != -1
                    "
                    @click="updateGroup(data)"
                    ><Edit
                  /></el-icon>
                  <a
                    style="margin-left: 8px"
                    v-if="
                      authData.indexOf('n_J7e61MeDEmTmpgqtfwms5PJsu8hn') != -1
                    "
                    @click="removeGroup(data)"
                  >
                    <el-icon color="#0072F6"><Close /></el-icon>
                  </a>
                </span>
              </span>
            </template>
          </el-tree>
        </el-scrollbar>
      </div>
    </div>
    <div class="right-wp">
      <div class="title">
        {{ titleName.length > 0 ? titleName.join("-") : "监控列表" }}
      </div>
      <div class="content">
        <common-table
          ref="userTable"
          tableHeight="calc(100vh - 325px)"
          :ischeck="false"
          :ispaging="true"
          :apiName="BasicApi.getMonitorList"
          :filters="filters"
          :extraParame="extraParame"
          :columns="tableColumns"
          @statusChange="statusChange"
          @previewMonitor="previewMonitor"
          @updateMonitor="updateMonitor"
          @delete="deleteMonitor"
          @edit="memberEdit"
        >
          <template #me_hik_status="{ row }">
            <span
              :style="{
                color: row.me_hik_status == 2 ? ' #67C23A' : '#ccc',
              }"
              >{{ row.me_hik_status == 2 ? "在线" : "离线" }}</span
            >
          </template>

          <template #operate>
            <el-button
              type="primary"
              round
              @click="showOrganizationAdd"
              v-if="authData.indexOf('n_1HfV84lV481SivX3gwMPuAolkgc') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增分组
            </el-button>
            <el-button
              class="black-bg-btn"
              round
              @click="showAddDialog"
              v-if="authData.indexOf('n_1HfV84lV481SivX3gwMPuAolkgc') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增监控
            </el-button>
          </template>
        </common-table>
      </div>
    </div>

    <!-- 新增分组弹框 -->
    <w-dialog
      ref="organization"
      class="organization"
      :title="isEditGroup ? '编辑分组' : '新增分组'"
      width="38%"
      btnWidth="140px"
      top="20vh"
      :confirmText="isEditGroup ? '确认编辑' : '确认新增'"
      @wConfirm="orgAddConfirm"
    >
      <el-form
        class="org-form"
        ref="orgFormObj"
        :model="orgForm"
        :rules="orgRules"
        labelPosition="top"
      >
        <el-form-item label="分组名称" prop="mg_name"
          ><el-input
            v-model="orgForm.mg_name"
            placeholder="请输入分组名称"
          ></el-input
        ></el-form-item>
        <el-form-item label="所属园区" prop="mg_sid" v-if="!isEditGroup">
          <el-select
            v-model="orgForm.mg_sid"
            clearable
            placeholder="请选择所属园区"
          >
            <el-option
              :label="item.s_name"
              :value="item.s_id"
              v-for="item in projectSelect"
              :key="item.s_id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="分组排序" prop="mg_sort"
          ><el-input
            v-model="orgForm.mg_sort"
            type="number"
            placeholder="请输入分组排序"
          ></el-input
        ></el-form-item>
        <el-form-item v-if="isEditGroup" label="状态">
          <el-popconfirm
            :title="`确定要${editGroupData.mg_status == 1 ? '禁用' : '开启'}${
              editGroupData.mg_name
            }吗?`"
            @confirm="statusChangeGroup(editGroupData)"
          >
            <template #reference>
              <el-switch
                v-model="editGroupData.mg_status"
                inline-prompt
                :active-text="'开启'"
                :inactive-text="'禁用'"
                :width="45"
                :active-value="1"
                :inactive-value="2"
                :before-change="() => false"
              />
            </template>
          </el-popconfirm>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 新增/编辑成员弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑监控' : '新增监控'"
      width="40%"
      btnWidth="140px"
      top="15vh"
      :confirmText="isEdit ? '确认编辑' : '确认新增'"
      @wConfirm="userAddConfirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="设备名称" prop="me_name"
          ><el-input
            v-model="ruleForm.me_name"
            placeholder="请输入设备名称"
          ></el-input
        ></el-form-item>
        <el-form-item v-if="!isEdit" label="设备序列号" prop="me_hik_no"
          ><el-input
            v-model="ruleForm.me_hik_no"
            placeholder="请输入设备序列号"
          ></el-input
        ></el-form-item>
        <el-form-item
          v-if="!isEdit"
          label="设备验证码"
          prop="me_hik_validate_code"
        >
          <el-input
            v-model="ruleForm.me_hik_validate_code"
            placeholder="请输入设备验证码"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序顺序" prop="me_sort"
          ><el-input
            v-model="ruleForm.me_sort"
            placeholder="请输入排序顺序"
          ></el-input
        ></el-form-item>
        <el-form-item v-if="!isEdit" label="所属园区" prop="department">
          <el-select
            v-model="ruleForm.s_id"
            @change="changeProject"
            clearable
            placeholder="请选择所属园区"
          >
            <el-option
              :label="item.s_name"
              :value="index"
              v-for="(item, index) in treeData"
              :key="item.s_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!isEdit" label="所属分组" prop="organization">
          <el-select
            v-model="ruleForm.me_mgid"
            clearable
            placeholder="请选择所属分组"
          >
            <el-option
              :label="item.mg_name"
              :value="item.mg_id"
              v-for="item in groupList"
              :key="item.mg_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
      </el-form>
    </w-dialog>

    <!-- 预览监控 弹框 -->
    <w-dialog
      ref="previewVideo"
      class="organization"
      hideFooter="true"
      @iconClose="wCancelPreviewVideo"
      title="监控预览"
      width="950px"
      btnWidth="140px"
      top="10vh"
    >
      <div>
        <div class="mcontent" v-loading="authLoading">
          <div class="video-switch">
            <div class="video-switch-tab">
              <div class="video-switch-tab-line" :style="switchLineStyle"></div>
              <div
                class="video-switch-tab-btn"
                @click="switchTo(0)"
                :class="{ 'tab-active': switchVideo === 0 ? true : false }"
              >
                实时预览
              </div>
              <div
                class="video-switch-tab-btn"
                @click="switchTo(1)"
                :class="{ 'tab-active': switchVideo === 1 ? true : false }"
              >
                录像回放
              </div>
            </div>
          </div>
          <div id="playWnd" style="width: 900px; height: 500px"></div>
        </div>
      </div>
    </w-dialog>
    <!-- 权限范围 弹框 -->
    <RoleRange ref="roleRangeRef" @submit_suc="roleRangeSuc"></RoleRange>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, watch, nextTick } from "vue";
import { Search, Close, Edit } from "@element-plus/icons-vue";
import { ElMessageBox, ElMessage } from "element-plus";
import cookie from "@/utils/cookie";

import { BasicApi } from "@/plugins/api.js";
import { useStore } from "vuex";
import RoleRange from "@/views/basic/children/RoleRange.vue";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);

const switchLineStyle = computed(() =>
  switchVideo.value === 0
    ? { transform: "translateX(0px)" }
    : { transform: "translateX(88px)" }
);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

const groupList = ref([]);
const changeProject = (e) => {
	ruleForm.me_mgid = ''
  groupList.value = treeData.value[e].group_list;
};
const statusChangeGroup = () => {
  let data = {
    type: 1,
    mg_status: editGroupData.value.mg_status == 1 ? 2 : 1,
    mg_id: editGroupData.value.mg_id,
  };
  BasicApi.updateMonitor(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("状态修改成功！");
      organization.value.close();
      // 重新获取套餐列表数据
      getTreeData();
    } else {
      let msg = res.Message ? res.Message : "状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/**************监控相关***************/

const switchVideo = ref(0); // 0实时预览 1录像回放
var oWebControl = ref(null);
const self = reactive({
  appKey: "",
  ezvizToken: "",
  platformId: "",
  layout: 0,
  deviceSerial: "",
  channelNo: "",
  startTime: "",
  endTime: "",
  deviceClass: "0",
});
const projectSelect = ref([]);
/**
 * 生命周期
 */
onMounted(() => {
  getTreeData();
  BasicApi.getHikEzvizAccount({}).then((res) => {
    if (res.Code == 200) {
      self.appKey = res.Data.appKey;
      self.ezvizToken = res.Data.token;
    }
  });

  BasicApi.getScenicByProject({}).then((res) => {
    if (res.Code == 200) {
      projectSelect.value = res.Data;
    }
  });
});

/**
 * video 初始化及配置
 * @param {*video挂载的dom id} id
 * @param {*连接成功的回调} cbConnectSuccess
 * @param {*连接报错的回调} cbConnectError
 * @param {*连接关闭的回调} cbConnectClose
 */
function WebControlInit(id, cbConnectSuccess, cbConnectError, cbConnectClose) {
  return new WebControl({
    szPluginContainer: id,
    iServicePortStart: 14510, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
    iServicePortEnd: 14519, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
    cbConnectSuccess: cbConnectSuccess,
    cbConnectError: cbConnectError,
    cbConnectClose: cbConnectClose,
  });
}

// 设置窗口控制回调
async function cbConnectSuccess() {
  await oWebControl.value.JS_SetWindowControlCallback({
    cbIntegrationCallBack: cbIntegrationCallBack,
  });
  //创建视频窗口
  await oWebControl.value
    .JS_StartService("window", {
      dllPath: "./chain/cloudTransform.dll",
    })
    .then(function () {});
  await oWebControl.value.JS_CreateWnd("playWnd", 900, 500).then(function () {
    // console.log("JS_CreateWnd success");
  });
}
function cbConnectError() {
  oWebControl.value = null;
  console.error("确认本地进程是否已安装并开启成功！");
}

function cbConnectClose() {
  oWebControl.value = null;
}
// 监听视频控件的事件
function cbIntegrationCallBack() {
  // if (oData.responseMsg.eventName === 'FireTransFunction') {
  // 	// 如果视频控件需要请求服务端数据，这里可以处理透传逻辑
  // }
}
/**
 * 设置事件回调信息
 */
const callbackMessage = ref("");
function showCBInfo(message) {
  callbackMessage.value =
    callbackMessage.value + JSON.stringify(message) + "\n\n";
}

// 初始化视频
function initVideo() {
  if (!self.appKey || !self.ezvizToken) {
    ElMessage.error("请输入appkey和token");
    return;
  }
  // 初始化入参
  let argumentsPram = {
    layout: self.layout,
    userName: "",
    waterMark: "",
    intelligenceEnable: 1,
    isRecord: 1,
    isSetPos: 1,
    motionDetectEnable: 0,
    playBackAlarmOverlyingEnable: 0,
    response: {
      code: 0,
      message: null,
      data: {
        appKey: self.appKey,
        ezvizToken: self.ezvizToken,
        videoLevel: 0,
        showMainTool: 1,
        showSubTool: 1,
        waterMark: "1",
        userName: cookie.getCookie("user_name"), //水印
        platformId: "1",
      },
    },
  };
  // 调用视频控件初始化方法
  oWebControl.value
    .JS_RequestInterface({
      funcName: "Init",
      arguments: encodeURI(JSON.stringify(argumentsPram)),
    })
    .then(function (oData) {
      showCBInfo(oData.responseMsg);
      ElMessage.success("视频初始化成功");
      playVideo();
    });
}
const wCancelPreviewVideo = () => {
  //隐藏窗口
  oWebControl.value.JS_HideWnd();
  oWebControl.value.JS_DestroyWnd();
  previewVideo.value.close();
};
const switchTo = (num) => {
  switchVideo.value = num;
  if (oWebControl.value) {
    oWebControl.value
      .JS_RequestInterface({
        funcName: "ChangeModel",
        arguments: encodeURI(
          JSON.stringify({
            model: num,
          })
        ),
      })
      .then(function () {
        playVideo();
      });
  }
};
// 播放视频
const playVideo = () => {
  if (!self.deviceSerial || !self.channelNo) {
    ElMessage.error("请输入设备序列号和通道号");
    return;
  }
  // 预览入参
  let argumentsPram = {
    response: {
      code: 0,
      message: null,
      data: {
        deviceSerial: self.deviceSerial,
        channelCode: self.channelNo,
        channelNo: self.channelNo,
        codeIsEncrypt: 0,
        validateCode: "",
        deviceClass: self.deviceClass,
        deviceType: "10242",
        //deviceType:"10240",
        channelId: "2222222222",
        channelName: "channelNameTest",
        storeName: "storeName",
        storeId: "storeid",
        platformId: "1",
        //startTime:'2021-03-21 01:00:00',
        //endTime:'2021-03-21 01:02:00',
        //Limited_start:limitStart,
        //Limited_end:limitEnd,
      },
    },
  };

  // 回放时对应的两个字段
  if (switchVideo.value === 1) {
    if (self.startTime) argumentsPram.response.data.startTime = self.startTime;
    if (self.endTime) argumentsPram.response.data.endTime = self.endTime;
  }
  // 调用视频控件预览方法
  oWebControl.value
    .JS_RequestInterface({
      funcName: switchVideo.value === 0 ? "StartPreview" : "StartPlayback",
      arguments: encodeURI(JSON.stringify(argumentsPram)),
    })
    .then(function (oData) {
      showCBInfo(oData.responseMsg);
      ElMessage.success("预览/回放成功！");
    });
};
/**************监控相关***************/

/** 搜索框绑定值 */
const filterText = ref("");
/** 树形结构组件对象 */
const tree = ref(null);
/** 树结构加载标识 */
const treeLoading = ref(false);
/** 当前选择的组织 */
const currentOrg = ref(null);
/** 树形结构 数据 */
const treeData = ref([]);
/** 默认展开节点 */
const defaultExpanded = ref([]);
/** 默认选择节点 */
const defaultChecked = ref([]);
/** 节点点击回调 */
const extraParame = ref({ type: 2, mg_id: "", s_id: "" });
const nodeClick = (node) => {
  extraParame.value.mg_id = node?.mg_id;
  extraParame.value.s_id = node?.s_id;

  currentOrg.value = node;
  tree.value.setCheckedNodes([node]);
  titleName.value = [];
  nextTick(() => {
    userTable.value.tableLoad();
  });
};

/** 节点展开事件 */
const nodeExpand = (data) => {
  defaultExpanded.value.push(data.org_id);
};
/** 节点关闭事件 */
const nodeCollapse = (data) => {
  let index = defaultExpanded.value.findIndex((item) => item == data.org_id);
  defaultExpanded.value.splice(index, 1);
};
/** 获取组织树形结构数据 */
const getTreeData = () => {
  treeLoading.value = true;
  BasicApi.getMonitorList({
    keywords: filterText.value,
    type: 1,
  }).then((res) => {
    if (res.Code === 200) {
      treeData.value = res.Data ? res.Data : [];
      treeData.value.forEach((resp) => {
        resp.group_list.forEach((group) => {
          group.s_name = group.mg_name;
        });
      });
    } else {
      let msg = res.Message ? res.Message : "获取树形组织结构失败！";
      ElMessage.error(msg);
    }
    treeLoading.value = false;
  });
};

/** 当前组织及父级名称 */
const titleName = ref([]);

/** 筛选条件列表 */
const filters = ref([
  {
    name: "keywords",
    filterType: "search",
    value: "",
    placeholder: "搜索监控设备名称",
  },
]);
/** 表格对象 */
const userTable = ref(null);
/** 当前操作 表格行数据 */
const currentRow = ref(null);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "me_id",
    label: "设备ID",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "me_name",
    label: "设备名称",
    minWidth: 120,
    color: "--text-third-color",
  },

  {
    prop: "me_hik_no",
    label: "设备序列号",
    minWidth: 120,
    color: "--text-color",
  },
  {
    type: "customRender",
    prop: "me_hik_status",
    label: "在线状态",
    minWidth: 80,
  },
  {
    type: "switch",
    prop: "me_status",
    label: "业务状态",
    minWidth: 80,
    token: "n_KLF6TYELt0lWJcY6WBgqf1sZKho",
  },

  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 270,
    bottons: [
      {
        name: "预览",
        action: "previewMonitor",
        token: "n_YPZkKmguidRMULpQrhCjHVfIEXx2",
        className: "black-font-btn",
      },
      {
        name: "编辑",
        action: "updateMonitor",
        token: "n_KLF6TYELt0lWJcY6WBgqf1sZKho",
        className: "theme-font-btn",
      },
      {
        name: "删除设备",
        action: "delete",
        token: "n_J7e61MeDEmTmpgqtfwms5PJsu8hn",
        className: "black-font-btn",
      },
    ],
  },
]);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    me_id: row.me_id,
    me_status: row.me_status == 1 ? 2 : 1,
    type: 2,
  };
  BasicApi.updateMonitor(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("状态修改成功！");
      // 重新获取套餐列表数据
      userTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 预览 */
const previewVideo = ref(null);
const previewMonitor = async (row) => {
  currentRow.value = row;
  previewVideo.value.show();
  self.deviceSerial = row.me_hik_no;
  self.channelNo = 1;
  // 初始化视频控件
  oWebControl.value = await WebControlInit(
    "playWnd",
    cbConnectSuccess,
    cbConnectError,
    cbConnectClose
  );
  setTimeout(() => {
    initVideo();
  }, 3000);
};
/** 删除设备 */
const deleteMonitor = (row) => {
  ElMessageBox.confirm("确认删除?", "提示", {
    confirmButtonText: "确认删除",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      BasicApi.delMonitor({ type: 2, me_id: row.me_id }).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("删除成功！");
          // 重新获取表格数据
          userTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : "删除失败！";
          ElMessage.error(msg);
        }
      });
    })
    .catch((err) => {
        ElMessage.error(err);
    });
};
/** 表格编辑 */
const memberEdit = (row) => {
  currentRow.value = row;
  isEdit.value = true;
  ruleForm.name = row.u_name;
  ruleForm.tel = row.u_tel;
  ruleForm.department = row.u_duid;
  ruleForm.organization = row.uo_oid;
  ruleForm.role = JSON.parse(row.u_role);
  addDialog.value.show();
  getPostData();
  getRoleData();
};

/** 新增组织 弹框 */
const organization = ref(null);
/** 组织 表单对象 */
const orgFormObj = ref(null);
/** 组织 表单数据对象 */
const orgForm = reactive({
  mg_name: "", // 组织名称
  mg_sid: "", // 上级组织
  mg_sort: "", // 组织排序
});
const isEditGroup = ref(false);
const editGroupData = ref("");
const updateGroup = (data) => {
  editGroupData.value = data;
  orgForm.mg_name = data.mg_name;
  orgForm.mg_sid = data.mg_sid;
  orgForm.mg_sort = data.mg_sort;
  organization.value.show();
  isEditGroup.value = true;
};

const removeGroup = (data) => {
  ElMessageBox.confirm("确认删除?", "提示", {
    confirmButtonText: "确认删除",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      BasicApi.delMonitor({ type: 1, mg_id: data.mg_id }).then((res) => {
        if (res.Code == 200) {
          ElMessage.success("删除成功！");
          // 重新获取一下组织数据
          getTreeData();
        } else {
          let msg = res.Message ? res.Message : "删除失败！";
          ElMessage.error(msg);
        }
      });
    })
    .catch((err) => {
        ElMessage.error(err);
    });
};
/** 组织 表单规则对象 */
const orgRules = reactive({
  mg_name: [
    {
      required: true,
      message: "请输入分组名称",
      trigger: "blur",
    },
  ],
  mg_sid: [
    {
      required: true,
      message: "请选择园区",
      trigger: "change",
    },
  ],
  mg_sort: [
    {
      required: true,
      message: "请输入分组排序",
      trigger: "blur",
    },
  ],
});
/** 弹出 新增组织 弹框 */
const showOrganizationAdd = () => {
  // 表单验证重置
  if (orgFormObj.value) {
    orgFormObj.value.resetFields();
  }
  isEditGroup.value = false;
  orgForm.mg_name = "";
  orgForm.mg_sid = "";
  orgForm.mg_sort = "";
  organization.value.show();
};
/** 新增组织 确认 */
const orgAddConfirm = () => {
  orgFormObj.value.validate((valid) => {
    if (valid) {
      organization.value.isLoading = true;
      let data = {
        mg_name: orgForm.mg_name,
        mg_sid: orgForm.mg_sid,
        mg_sort: orgForm.mg_sort,
        type: 1,
      };
      let url = "addMonitor";
      if (isEditGroup.value) {
        url = "updateMonitor";
        data.mg_id = editGroupData.value.mg_id;
      }
      BasicApi[url](data).then((res) => {
        organization.value.isLoading = false;
        if (res.Code === 200) {
          ElMessage.success(
            isEditGroup.value ? "编辑组织成功！" : "新增组织成功"
          );
          organization.value.close();
          defaultChecked.value = tree.value.getCheckedKeys();
          // 重新获取一下组织数据
          getTreeData();
        } else {
          let msg = res.Message ? res.Message : "新增组织失败！";
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 新增/编辑 成员弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  me_mgid: "", // 监控分组ID
  me_name: "", // 设备名称
  me_hik_no: "", // 设备序列号
  me_hik_validate_code: "", // 设备验证码
  me_sort: "", //排序 顺序
});
/** 表单规则对象 */
const rules = reactive({
  me_name: [
    {
      required: true,
      message: "请输入设备名称",
      trigger: "blur",
    },
  ],
  me_hik_no: [
    {
      required: true,
      message: "请输入设备序列号",
      trigger: "blur",
    },
  ],
  me_mgid: [
    {
      required: true,
      message: "请选择所属分组",
      trigger: "change",
    },
  ],
  me_hik_validate_code: [
    {
      required: true,
      message: "请输入设备验证码",
      trigger: "blur",
    },
  ],
  me_sort: [
    {
      required: true,
      message: "请输入排序 顺序",
      trigger: "blur",
    },
  ],
});
/** 岗位列表数据 */
const postList = ref([]);
/** 角色列表数据 */
const roleList = ref([]);
/** 弹出 新增/编辑成员 弹框 */
const showAddDialog = () => {
  // 表单验证重置
  if (addForm.value) {
    addForm.value.resetFields();
  }
  ruleForm.me_name = "";
  ruleForm.me_mgid = "";
  ruleForm.me_hik_no = "";
  ruleForm.me_hik_validate_code = "";
  ruleForm.me_sort = "";
  groupList.value = [];
  isEdit.value = false;
  addDialog.value.show();
  getPostData();
  getRoleData();
};
/** 获取岗位数据 */
const getPostData = () => {
  BasicApi.getDuty().then((res) => {
    if (res.Code === 200) {
      postList.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取部门数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 获取角色数据 */
const getRoleData = () => {
  BasicApi.getRole().then((res) => {
    if (res.Code === 200) {
      roleList.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取角色数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 确认添加成员 */
const userAddConfirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        me_name: ruleForm.me_name,
        me_mgid: ruleForm.me_mgid,
        me_hik_no: ruleForm.me_hik_no,
        me_hik_validate_code: ruleForm.me_hik_validate_code,
        me_sort: ruleForm.me_sort,
        type: 2,
      };
      let url = "addMonitor";
      if (isEdit.value) {
        data.me_id = currentRow.value.me_id;
        url = "updateMonitor";
      }
      BasicApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        let text = isEdit.value ? "编辑成员" : "新增成员";
        if (res.Code === 200) {
          ElMessage.success(`${text}成功！`);
          addDialog.value.close();
          if (isEdit.value) {
            // 重新获取表格数据
            userTable.value.tableLoad();
          }
        } else {
          let msg = res.Message ? res.Message : `${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 是否正在加载权限数据 */
const authLoading = ref(false);
// 成员权限范围弹框挂载
const roleRangeRef = ref(null);
// 打开编辑弹出框
const updateMonitor = (row) => {
  addDialog.value.show();
  isEdit.value = true;
  ruleForm.me_name = row.me_name;
  ruleForm.me_mgid = row.me_mgid;
  ruleForm.me_hik_no = row.me_hik_no;
  ruleForm.me_hik_validate_code = row.me_hik_validate_code;
  ruleForm.me_sort = row.me_sort;
  currentRow.value = row;
};
// 编辑权限范围回显
const roleRangeSuc = () => {
  userTable.value.tableLoad();
};
/** 变更成员权限范围 **/
</script>

<style lang="scss">
.el-cascader {
  width: 100%;
}

.video-switch {
  height: 48px;
  width: 900px;
  background: #333333;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.video-switch-tab {
  height: 48px;
  margin: 0 auto;
  transition: transform 0.3s;
  position: relative;
}

.video-switch-tab-line {
  position: absolute;
  width: 88px;
  top: 0;
  left: 0;
  height: 3px;
  background-color: #e72528;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.video-switch-tab-btn {
  font-size: 12px;
  font-weight: 801;
  float: left;
  font-family: "MicrosoftYaHei-Bold";
  line-height: 48px;
  color: var(--text-white-color);
  opacity: 0.6;
  width: 88px;
  box-sizing: content-box;
  text-align: center;
}

.members {
  font-family: "Source Han Sans CN";
  display: flex;
  background-color: var(--theme-bg-color);
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  .left-wp {
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--bg-content-color);
    padding: 20px 0;
    box-sizing: border-box;

    .input-wp {
      padding: 0 15px;
    }

    > .tree-wp {
      margin-top: 15px;
      height: calc(100% - 58px);

      .el-tree {
        padding: 0 15px;

        .el-tree-node {
          .el-tree-node__content {
            height: 40px;

            > label.el-checkbox {
              display: none;
            }

            .el-tree-node__expand-icon {
              font-size: 16px;
            }
          }

          .el-tree-node__content:hover {
            background-color: var(--search-bg-color);
          }
        }

        .el-tree-node:focus {
          > .el-tree-node__content {
            background-color: var(--search-bg-color);
          }
        }

        .el-tree-node.is-checked {
          > .el-tree-node__content {
            background-color: var(--search-bg-color);

            > .el-tree-node__label {
              color: var(--theme-color);
            }

            > .el-tree-node__expand-icon {
              color: var(--theme-color);
            }

            > .el-tree-node__expand-icon.is-leaf {
              color: transparent;
            }
          }
        }
      }
    }
  }

  .right-wp {
    flex: 3;
    margin-left: 16px;
    border-radius: 10px;
    background-color: var(--bg-content-color);

    .content {
      padding: 20px;
    }
  }

  .organization {
    .el-dialog {
      min-width: 640px;

      .el-dialog__body {
        padding: 0 15px 50px;

        .org-form {
          display: flex;
          flex-wrap: wrap;

          .el-form-item {
            width: 50%;
            padding: 20px 15px 0;
            margin-bottom: 0;

            .el-form-item__content {
              .el-select {
                width: 100%;

                .el-input {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .add-dialog {
    .el-dialog {
      min-width: 840px;

      .el-dialog__body {
        padding: 0 15px 40px;

        .add-form {
          display: flex;
          flex-wrap: wrap;

          .el-form-item {
            width: 50%;
            padding: 20px 15px 0;
            margin-bottom: 0;

            .el-form-item__content {
              .el-select {
                width: 100%;

                .el-input {
                  width: 100%;
                }
              }
            }
          }

          .el-form-item.block {
            width: 100%;
          }

          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  .auth-dialog {
    .el-dialog {
      min-width: 840px;

      .el-dialog__body {
        padding: 0;

        .mcontent {
          padding: 25px 25px 50px;
          min-height: 600px;

          .name {
            font-size: 15px;
            color: var(--top-text-color);
            margin-bottom: 15px;
          }

          .select {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
</style>
